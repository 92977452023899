/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Imports */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

import "intersection-observer";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import {
    TweenMax,
    TimelineMax,
    ScrollToPlugin,
    Draggable,
    CSSPlugin,
} from "gsap/all";


import "./ThrowPropsPlugin";

import {addListenerMulti} from "./helpers";

// Initialize Plugins to avoid tree shaking issues
const plugins = [ScrollToPlugin, ThrowPropsPlugin, CSSPlugin];
const axios = require("axios");

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Variables */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

const header = document.querySelector("header"),
    body = document.querySelector("body"),
    main = document.querySelector("main");

const supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Ajax Functions */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

let ajaxFunctionsArray = [];

function ajaxFn() {
    for (let i = 0; i < ajaxFunctionsArray.length; i++) {
        ajaxFunctionsArray[i]();
    }
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Header */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

let hHeader, hBC, hMenu;

function getHeightHeader() {
    hBC = document.querySelectorAll(".breadcrumbs")[0].offsetHeight;
    hMenu =
        document.querySelector("#description").offsetTop +
        document.querySelector("#description").offsetHeight;
    hHeader = hMenu + hBC;
}

function setHeightHeader() {
    getHeightHeader();
    document.documentElement.style.setProperty("--heightHeader", `${hHeader}px`);
    document.documentElement.style.setProperty("--heightMenu", `${hMenu}px`);
}

addListenerMulti(window, "resize", setHeightHeader);
addListenerMulti(
    document,
    "DOMContentLoaded, readystatechange",
    setHeightHeader
);

function onElementHeightChange(elm, callback) {
    var lastHeight = elm.clientHeight,
        newHeight;
    (function run() {
        newHeight = elm.clientHeight;
        if (lastHeight != newHeight) callback();
        lastHeight = newHeight;

        if (elm.onElementHeightChangeTimer)
            clearTimeout(elm.onElementHeightChangeTimer);

        elm.onElementHeightChangeTimer = setTimeout(run, 200);
    })();
}

onElementHeightChange(document.body, function () {
    setHeightHeader();
    //   console.log(hHeader, hBC, hMenu);
});

// lastScrollTop = st <= 0 ? 0 : st;

const getScrollTop = function () {
    // Use scrollTop because it's faster than getBoundingClientRect()
    return (document.scrollingElement || document.documentElement).scrollTop;
};

const loop = function (style, previousScrollTop) {
    // Continue loop
    const repeat = () => {
        // It depends on the browser, but it turns out that closures
        // are sometimes faster than .bind or .apply.
        requestAnimationFrame(() => loop(style, previousScrollTop));
    };

    const scrollTop = getScrollTop();

    // Only continue when scrollTop has changed
    if (previousScrollTop === scrollTop) return repeat();
    else previousScrollTop = scrollTop;

    if (
        scrollTop <= hHeader &&
        header.classList.contains("header--hidden") &&
        header.classList.contains("header--show")
    ) {
        header.classList.remove("header--show");
    }

    repeat();
};

loop();

const fixedTarget = document.querySelectorAll(".breadcrumbs")[0];
const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        const target = entry.target;
        if (entry.isIntersecting) {
            header.classList.remove("header--hidden");
            setHeightHeader();
            //   console.log(hHeader, hBC, hMenu);
        } else {
            header.classList.add("header--hidden");
        }
    });
});

observer.observe(document.querySelector("#indicator"));

function toggleHeader() {
    if (header.classList.contains("header--hidden")) {
        if (header.classList.contains("header--show")) {
            header.classList.remove("header--show");
        } else {
            header.classList.add("header--show");
        }
    }
}

document.querySelector("#btn__menu").addEventListener("click", toggleHeader);

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Breadcrumbs */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

const BCprimary = document.querySelector("#breadcrumbs__primary"),
    BCsecondary = document.querySelector("#breadcrumbs__secondary");

/* Make Draggable */

Draggable.create(".breadcrumbs--draggable", {
    type: "scrollLeft",
    edgeResistance: 0.8,
    dragResistance: 0.05,
    throwProps: true,
    lockAxis: true,
    dragClickables: true,
});

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* AJAX Loading */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/**
 * Object.prototype.forEach() polyfill
 * https://gomakethings.com/looping-through-objects-with-es6/
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!Object.prototype.forEach) {
    Object.defineProperty(Object.prototype, "forEach", {
        value: function (callback, thisArg) {
            if (this == null) {
                throw new TypeError("Not an object");
            }
            thisArg = thisArg || window;
            for (var key in this) {
                if (this.hasOwnProperty(key)) {
                    callback.call(thisArg, this[key], key, this);
                }
            }
        },
    });
}

document.body.addEventListener("click", function (event) {
    // console.log(event.target);
    // console.log(
    //     "~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~"
    // );
    // console.log(event.target);
    if (!event.target.closest("a[data-page]")) return;
    if (event.ctrlKey || event.metaKey) {
        return;
    }
    event.preventDefault();
    changePage(event.target);
});

function analytics(el) {
    let current = checkCurrent(),
        target = checkTarget(el);
    let toRemove = {},
        toAdd = {};

    current.forEach(function (item, key) {
        if (current[key] !== undefined && target[key] !== item) {
            toRemove[key] = item;
        }
    });

    target.forEach(function (item, key) {
        if (target[key] !== undefined && current[key] !== item) {
            toAdd[key] = item;
        }
    });

    delete toRemove.type;
    delete toAdd.type;

    return [current, target, toRemove, toAdd];
}

function checkCurrent() {
    let bodyClasses = body.classList;
    let current = {};
    let currents = ["type", "page", "tax", "single"];

    currents.forEach((curr) => {
        current[curr] = undefined;
    });
    let type;

    bodyClasses.forEach((bodyClass) => {
        if (
            bodyClass.startsWith("page--") ||
            bodyClass.startsWith("tax--") ||
            bodyClass.startsWith("single--")
        ) {
            type = bodyClass.split("--")[0];
            current[type] = bodyClass.split("--")[1];
        }
    });
    current.type = type;
    return current;
}

function checkTarget(el) {
    let target = {};
    let targets = ["type", "page", "tax", "single"];

    targets.forEach((tar) => {
        target[tar] = el.dataset[tar];
    });
    return target;
}

let promiseFetchContent = function () {
    return new Promise(function (resolve, reject) {
        resolve("promiseFetchContent OK");
    });
};

let removeCurrentBC = function (toRemove) {
    return new Promise(function (resolve, reject) {
        let clearCurrBC1 = [],
            currBC2 = BCsecondary.querySelectorAll(".breadcrumbs__element");

        if (toRemove.page === "home") {
            let removeELs = document.querySelectorAll(
                "#breadcrumbs__primary ul li:not(.breadcrumbs__element--menu)"
            );
            [...removeELs].forEach((bc) => {
                clearCurrBC1.push(bc);
            });
        } else {
            toRemove.forEach(function (item, key) {
                let removeEl = BCprimary.querySelector(`[data-${key}="${item}"]`);
                if (removeEl) {
                    clearCurrBC1.push(removeEl.parentNode);
                }
            });
        }
        let tlremoveCurrentBC = new TimelineMax({
            paused: true,
        });

        tlremoveCurrentBC.add("start", 0);

        tlremoveCurrentBC.add(
            TweenMax.to(BCsecondary, 0.2, {
                className: "+=breadcrumbs--hidden",
                onComplete: function () {
                    getHeightHeader();
                    TweenMax.to(":root", 0.2, {
                        "--heightHeader": hHeader + "px",
                    });
                },
            })
        );

        if (currBC2 !== undefined) {
            tlremoveCurrentBC.add(
                TweenMax.to(currBC2, 0.2, {
                    className: "+=breadcrumb--fadeOut",
                    onComplete: function () {
                        currBC2.forEach(function (a) {
                            a.remove();
                        });
                    },
                }),
                "start"
            );
        }

        tlremoveCurrentBC.add(
            TweenMax.to(clearCurrBC1, 0.2, {
                className: "+=breadcrumb--fadeOut",
                onComplete: function () {
                    clearCurrBC1.forEach(function (a) {
                        a.remove();
                    });
                    resolve("ok");
                },
            })
        );

        tlremoveCurrentBC.play();
    });
};

let removeCurrentContent = function (currentPage, targetPage) {
    return new Promise(function (resolve, reject) {
        if (currentPage !== targetPage) {
            removeDescription();
        }
        let tlremoveCurrentContent = new TimelineMax();

        tlremoveCurrentContent
            .to(window, 0.3, {
                scrollTo: {y: 0, offsetY: 0, autoKill: false},
            })
            .to(main, 0.3, {
                autoAlpha: 0,
                onComplete: function () {
                    main.innerHTML = "";
                    document.querySelector("#related").innerHTML = "";
                    main.removeAttribute("style");
                    resolve("ok");
                },
            });
    });
};

let getTargetContent = function (targetUrl) {
    return new Promise(function (resolve, reject) {
        let targetContent;
        // console.log(targetUrl);
        axios
            .get(targetUrl)
            .then(function (response) {
                // handle success
                history.pushState(null, null, targetUrl);
                let parser = new DOMParser();
                targetContent = parser.parseFromString(response.data, "text/html");
                resolve(targetContent);
                //console.log(targetContent);
            })
            .catch(function (error) {
                // handle error
                targetContent = "Error";
                // console.log(error);
                resolve(targetContent);
            })
            .then(function () {
                return targetContent;
            });
    });
};

function addTargetBC(targetContent, target, toAdd) {
    // console.log("toAdd: ", toAdd);
    let targetBC1 = Array.from(
            targetContent.querySelectorAll(
                "#breadcrumbs__primary ul li:not(.breadcrumbs__element--menu)"
            )
        ),
        targetBC2 = Array.from(
            targetContent.querySelectorAll("#breadcrumbs__secondary ul li")
        );
    let addTargetBC1 = [];
    let addTargetBC2 = [];
    // console.log(target.page);
    if (toAdd.page === "home") {
        [...targetBC1].forEach((bc) => {
            bc.classList.add("breadcrumb--slideOut");
            document.querySelector("#breadcrumbs__primary ul").appendChild(bc);
            addTargetBC1.push(bc);
        });
    } else {
        toAdd.forEach(function (item, key) {
            let addEl = targetContent.querySelector(
                `#breadcrumbs__primary ul li [data-${key}="${item}"]`
            );
            // console.log(addEl);
            if (addEl) {
                addEl.parentNode.classList.add("breadcrumb--slideOut");
                document
                    .querySelector("#breadcrumbs__primary ul")
                    .appendChild(addEl.parentNode);
                addTargetBC1.push(addEl.parentNode);
            }
        });
    }

    let tladdTargetBC = new TimelineMax({
        paused: true,
    });

    tladdTargetBC.add(
        TweenMax.staggerTo(
            addTargetBC1,
            0.3,
            {className: "-=breadcrumb--slideOut"},
            0.1
        )
    );

    if (targetBC2.length > 0) {
        tladdTargetBC.add(
            TweenMax.to(BCsecondary, 0.2, {
                className: "-=breadcrumbs--hidden",
                onComplete: function () {
                    getHeightHeader();
                    TweenMax.to(":root", 0.2, {
                        "--heightHeader": hHeader + "px",
                    });
                },
            })
        );
        [...targetBC2].forEach((bc) => {
            document.querySelector("#breadcrumbs__secondary ul").appendChild(bc);
            bc.classList.add("breadcrumb--slideOut");
            addTargetBC2.push(bc);
        });

        tladdTargetBC.add(
            TweenMax.staggerTo(
                addTargetBC2,
                0.3,
                {className: "-=breadcrumb--slideOut"},
                0.1
            )
        );
    }

    tladdTargetBC.play();
}

function addTargetContent(targetContent, currentPage, targetPage) {
    let targetBodyClasses = targetContent.querySelector("body").classList,
        targetTitle = targetContent.getElementsByTagName("title")[0].innerHTML,
        targetMain = targetContent.querySelector("main").innerHTML,
        targetDescr = targetContent.querySelector("#description").innerHTML,
        targetRelContent = targetContent.querySelector("#related").innerHTML;

    main.innerHTML = targetMain;
    document.querySelector("#related").innerHTML = targetRelContent;
    replaceHead(targetBodyClasses, targetTitle);

    TweenMax.to(main, 0.3, {
        autoAlpha: 1,
        y: 0,
        onComplete: function () {
            if (currentPage !== targetPage) {
                replaceDescription(targetDescr, targetBodyClasses);
            } else {
                scrollToPost(targetBodyClasses);
            }
            main.removeAttribute("style");
        },
    });
}

function scrollToPost() {
    let offset = getComputedStyle(document.documentElement).getPropertyValue(
        "--heightMenu"
    );
    TweenMax.to(window, 0.3, {
        scrollTo: {y: parseInt(offset, 10), offsetY: 0, autoKill: false}
    });
}

let description = document.querySelector("#description");

function removeDescription() {
    TweenMax.to(description, 0.3, {
        className: "+=description--hidden"
    });
}

function replaceHead(targetBodyClasses, targetTitle) {
    body.className = targetBodyClasses.value;
    document.title = targetTitle;
}

function replaceDescription(targetDescr, targetBodyClasses) {
    let hCurrentDescription = description.offsetHeight;
    description.innerHTML = targetDescr;
    let hTargetDescription = description.offsetHeight;
    TweenMax.fromTo(
        description,
        0.3,
        {height: hCurrentDescription},
        {
            height: hTargetDescription,
            onComplete: function () {
                TweenMax.to(description, 0.3, {
                    className: "-=description--hidden",
                    onComplete: function () {
                        setHeightHeader();
                        scrollToPost(targetBodyClasses);
                        description.removeAttribute("style");
                    },
                });
            },
        }
    );
}

function changePage(el) {
    //Determine Current Page and Target Page
    let targetUrl = el.getAttribute("href");

    if (body.dataset.auth === "false" && targetUrl.includes("/locations/")) {
        window.location.href = targetUrl;
    } else {
        let info = analytics(el);
        let currentPage = info[0]["page"];
        let targetPage = info[1]["page"];

        //Create Blank Slate & Fetch New Content
        Promise.all([
            getTargetContent(targetUrl),
            removeCurrentBC(info[2]),
            removeCurrentContent(currentPage, targetPage),
        ]).then((values) => {
            addTargetBC(values[0], info[1], info[3]);
            addTargetContent(values[0], currentPage, targetPage);
            // RUN ALL FUNCTIONS FROM DOCUMENT READY
            ajaxFn();
        });
    }
}

window.onpopstate = function (event) {
    window.location.replace(document.location);
};

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Gallery */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

const Flickity = require("flickity-fade");

function initGalleries() {
    let galleries = document.querySelectorAll(".gallery");
    galleries.forEach((gallery) => {
        let flkty = new Flickity(gallery, {
            cellAlign: "left",
            wrapAround: true,
            imagesLoaded: true,
            pageDots: false,
            draggable: true,
            prevNextButtons: false,
        });

        let previousButton = gallery.parentNode.querySelector(".gallery--prev");
        let nextButton = gallery.parentNode.querySelector(".gallery--next");

        if (previousButton && nextButton) {
            previousButton.addEventListener("click", function () {
                flkty.previous();
            });

            nextButton.addEventListener("click", function () {
                flkty.next();
            });
        }
    });
}

document.addEventListener("DOMContentLoaded", initGalleries);
ajaxFunctionsArray.push(function () {
    initGalleries();
});

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Video */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

// Logic
function n(n) {
    return n > 9 ? "" + n : "0" + n;
}

function setTime(time) {
    let s = parseInt(time % 60),
        m = parseInt((time / 60) % 60);
    return n(m) + ":" + n(s);
}

function timeToSeconds(time) {
    return time.split(":").reduce((acc, time) => 60 * acc + +time);
}

function initMedia() {
    let players = document.querySelectorAll(".media");

    [...players].forEach((player) => {
        let media = player.classList.contains("video")
            ? player.querySelector("video:not(.poster__video)")
            : player.querySelector("audio");

        let progress = player.querySelector(".media__progress"),
            toggle = player.querySelector(".media__btn--play"),
            markers = player.querySelectorAll("[data-marker]"),
            markerImgs = player.querySelectorAll("[data-timestamp]"),
            seekBar = player.querySelector(".media__progress input"),
            btnMute = player.querySelector(".media__btn--mute"),
            btnFS = player.querySelector(".btn__toggle--fs"),
            currTime = player.querySelector(".media--current"),
            durTime = player.querySelector(".video__duration"),
            component = player.closest("section"),
            figure = player.closest('figure');

        function setTotalTime() {
            figure.classList.remove("media--playing");
            currTime.innerHTML = setTime(media.duration);
            if (durTime !== null) {
                durTime.innerHTML = setTime(media.duration);
            }
            total = true;
        }

        function togglePlay() {
            let playState = media.paused ? "play" : "pause";
            media[playState](); // Call play or paused method
        }

        function updateButton() {
            if (this.paused) {
                toggle.innerHTML = `Play`;
                figure.classList.remove("media--playing");
            } else {
                removePoster();
                toggle.innerHTML = `Pause`;
                figure.classList.add("media--playing");
            }
        }

        function jumpTo() {
            media.currentTime = parseFloat(timeToSeconds(this.dataset.marker));
            if (media.paused) {
                media.play();
            }
        }

        function timePassed() {
            if (markerImgs !== null) {
                markerImgs.forEach((markerImg) => {
                    let markerImgTS = timeToSeconds(
                        markerImg.getAttribute("data-timestamp")
                    );
                    if (media.currentTime >= markerImgTS) {
                        markerImg.classList.add("marker--passed");
                    } else {
                        markerImg.classList.remove("marker--passed");
                    }
                });
            }
        }

        function progressUpdate() {
            // console.log("progressUpdate");
            let percent = (media.currentTime / media.duration) * 100;
            seekBar.value = percent;
            seekBar.setAttribute("value", percent);
            timePassed();
            player.style.setProperty("--progress", `${percent}%`);
            let formatTime = setTime(media.currentTime);
            currTime.innerHTML = formatTime;
        }

        function scrub(e) {
            let scrubTime = (e.offsetX / progress.offsetWidth) * media.duration;
            media.currentTime = scrubTime;
        }

        function mute() {
            if (media.muted == false) {
                media.muted = true;
                btnMute.innerHTML = "Sound On";
            } else {
                media.muted = false;
                btnMute.innerHTML = "Sound Off";
            }
        }

        function removePoster() {
            let poster = player.querySelector(".poster");
            if (poster !== null) {
                poster.parentNode.removeChild(poster);
            }
        }

        let requestFullscreen = function (ele) {
            if (ele.requestFullscreen) {
                ele.requestFullscreen();
            } else if (ele.webkitRequestFullscreen) {
                ele.webkitRequestFullscreen();
            } else if (ele.mozRequestFullScreen) {
                ele.mozRequestFullScreen();
            } else if (ele.msRequestFullscreen) {
                ele.msRequestFullscreen();
            } else {
                console.log("Fullscreen API is not supported.");
            }
        };

        let exitFullscreen = function () {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else {
                console.log("Fullscreen API is not supported.");
            }
        };

        //Event listeners
        let total = false;

        setTimeout(function () {
            if (media.readyState >= 1 && !total) {
                // console.log(total, "readyStat = 1");
                setTotalTime();
            }
        }, 1000);

        window.addEventListener("load", function () {
            // console.log("loaded");
            if (!total) {
                setTotalTime();
            }
        });

        media.addEventListener("loadedmetadata", setTotalTime);

        media.addEventListener("ended", setTotalTime);
        media.addEventListener("click", togglePlay);
        media.addEventListener("play", updateButton);
        media.addEventListener("pause", updateButton);
        media.addEventListener("timeupdate", progressUpdate);

        toggle.addEventListener("click", togglePlay);
        if (markers !== null) {
            markers.forEach((marker) => marker.addEventListener("click", jumpTo));
        }
        if (btnMute !== null) {
            btnMute.addEventListener("click", mute);
        }

        if (btnFS !== null) {
            btnFS.addEventListener("click", function () {
                requestFullscreen(media);
            });
        }

        let mousedown = false;
        progress.addEventListener("click", scrub);
        progress.addEventListener("mousemove", (e) => mousedown && scrub(e));
        progress.addEventListener("click", (e) => mousedown && scrub(e));
        progress.addEventListener("mousedown", () => (mousedown = true));
        progress.addEventListener("mouseup", () => (mousedown = false));
    });
}

document.addEventListener("DOMContentLoaded", initMedia);
ajaxFunctionsArray.push(function () {
    initMedia();
});

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Lazy Load Videos */

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

function initLLVideos() {
    let lazyVideos = [].slice.call(document.querySelectorAll(".video--autoplay"));

    if ("IntersectionObserver" in window) {
        let lazyVideoObserver = new IntersectionObserver(function (
            entries,
            observer
        ) {
            entries.forEach(function (video) {
                if (!video.target.classList.contains("video--lazy")) {
                    if (video.isIntersecting) {
                        video.target.play();
                    } else {
                        video.target.pause();
                    }
                }
            });
        });

        lazyVideos.forEach(function (lazyVideo) {
            if (lazyVideo.classList.contains("video--lazy")) {
                for (let source in lazyVideo.children) {
                    let videoSource = lazyVideo.children[source];
                    if (
                        typeof videoSource.tagName === "string" &&
                        videoSource.tagName === "SOURCE"
                    ) {
                        videoSource.src = videoSource.dataset.src;
                        lazyVideo.load();
                        lazyVideo.classList.remove("video--lazy");
                    }
                }
            }
            lazyVideoObserver.observe(lazyVideo);
        });
    }
}

document.addEventListener("DOMContentLoaded", initLLVideos);
ajaxFunctionsArray.push(function () {
    initLLVideos();
});

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Map */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

let centerMapOnCountry;

function initMap() {
    if (google) {
        const geocoder = new google.maps.Geocoder();

        const mapEls = document.querySelectorAll(".map");

        if (mapEls.length !== 0) {

            mapEls.forEach((mapEl) => {
                const args = {
                    center: {lat: 40.4316725, lng: -3.6936844},
                    zoom: 8,
                    disableDefaultUI: true,
                    styles: [
                        {
                            featureType: "all",
                            elementType: "labels.text.fill",
                            stylers: [
                                {
                                    color: "#000000",
                                },
                            ],
                        },
                        {
                            featureType: "all",
                            elementType: "labels.text.stroke",
                            stylers: [
                                {
                                    color: "#ffffff",
                                },
                            ],
                        },
                        {
                            featureType: "administrative.province",
                            elementType: "all",
                            stylers: [
                                {
                                    visibility: "on",
                                },
                            ],
                        },
                        {
                            featureType: "landscape",
                            elementType: "all",
                            stylers: [
                                {
                                    saturation: "-39",
                                },
                                {
                                    lightness: "35",
                                },
                                {
                                    gamma: "1.08",
                                },
                            ],
                        },
                        {
                            featureType: "landscape",
                            elementType: "geometry",
                            stylers: [
                                {
                                    saturation: "0",
                                },
                            ],
                        },
                        {
                            featureType: "landscape.man_made",
                            elementType: "all",
                            stylers: [
                                {
                                    saturation: "-100",
                                },
                                {
                                    lightness: "10",
                                },
                            ],
                        },
                        {
                            featureType: "landscape.man_made",
                            elementType: "geometry.stroke",
                            stylers: [
                                {
                                    saturation: "-100",
                                },
                                {
                                    lightness: "-14",
                                },
                            ],
                        },
                        {
                            featureType: "poi",
                            elementType: "all",
                            stylers: [
                                {
                                    saturation: "-100",
                                },
                                {
                                    lightness: "10",
                                },
                                {
                                    gamma: "2.26",
                                },
                            ],
                        },
                        {
                            featureType: "poi",
                            elementType: "labels.text",
                            stylers: [
                                {
                                    saturation: "-100",
                                },
                                {
                                    lightness: "-3",
                                },
                            ],
                        },
                        {
                            featureType: "road",
                            elementType: "all",
                            stylers: [
                                {
                                    saturation: "-100",
                                },
                                {
                                    lightness: "54",
                                },
                            ],
                        },
                        {
                            featureType: "road",
                            elementType: "geometry.stroke",
                            stylers: [
                                {
                                    saturation: "-100",
                                },
                                {
                                    lightness: "-7",
                                },
                            ],
                        },
                        {
                            featureType: "road.arterial",
                            elementType: "all",
                            stylers: [
                                {
                                    saturation: "-100",
                                },
                            ],
                        },
                        {
                            featureType: "road.local",
                            elementType: "all",
                            stylers: [
                                {
                                    saturation: "-100",
                                },
                                {
                                    lightness: "-2",
                                },
                            ],
                        },
                        {
                            featureType: "transit",
                            elementType: "all",
                            stylers: [
                                {
                                    saturation: "-100",
                                },
                            ],
                        },
                        {
                            featureType: "water",
                            elementType: "geometry.fill",
                            stylers: [
                                {
                                    saturation: "-100",
                                },
                                {
                                    lightness: "100",
                                },
                            ],
                        },
                        {
                            featureType: "water",
                            elementType: "geometry.stroke",
                            stylers: [
                                {
                                    saturation: "-100",
                                },
                                {
                                    lightness: "-100",
                                },
                            ],
                        },
                    ],
                };

                const map = new google.maps.Map(mapEl, args);
                const bounds = new google.maps.LatLngBounds();

                // Center Fuction for Locations filter
                centerMapOnCountry = (country) => {
                    geocoder.geocode({address: country}, function (results, status) {
                        if (country !== "All" && status == google.maps.GeocoderStatus.OK) {
                            map.setCenter(results[0].geometry.location);
                            map.setZoom(6);
                        } else {
                            map.setCenter(bounds.getCenter());
                            map.setZoom(5);
                        }
                    });
                };

                // add a markers reference
                map.markers = [];

                const markerEls = mapEl.parentNode.querySelectorAll(".marker");

                //----------

                function HTMLMarker(lat, lng, title, permalink) {
                    this.lat = lat;
                    this.lng = lng;
                    this.title = title;
                    this.permalink = permalink;
                    this.pos = new google.maps.LatLng(lat, lng);
                }

                HTMLMarker.prototype = new google.maps.OverlayView();
                HTMLMarker.prototype.onRemove = function () {
                };

                //init your html element here
                HTMLMarker.prototype.onAdd = function () {
                    this.a = document.createElement("A");
                    this.a.href = this.permalink;
                    this.a.className = "marker__locations";
                    this.a.innerHTML = this.title;
                    this.a.style.position = "absolute";
                    var panes = this.getPanes();
                    panes.overlayImage.appendChild(this.a);
                };

                HTMLMarker.prototype.draw = function () {
                    var overlayProjection = this.getProjection();
                    var position = overlayProjection.fromLatLngToDivPixel(this.pos);
                    var panes = this.getPanes();
                    this.a.style.left = position.x + "px";
                    this.a.style.top = position.y - 36 + "px";
                };

                // ----------

                if (markerEls.length !== 0) {
                    markerEls.forEach((markerEl) => {
                        const title = markerEl.dataset.title,
                            permalink = markerEl.dataset.permalink,
                            lat = parseFloat(markerEl.dataset.lat),
                            lng = parseFloat(markerEl.dataset.lng),
                            latlng = {
                                lat,
                                lng,
                            },
                            marker = new google.maps.Marker({
                                position: latlng,
                                map: map,
                                visible: false,
                            });

                        let htmlMarker = new HTMLMarker(lat, lng, title, permalink);
                        htmlMarker.setMap(map);

                        // add to array
                        map.markers.push(marker);
                    });

                    // loop through all markers and create bounds
                    map.markers.forEach(function (marker) {
                        const loc = new google.maps.LatLng(
                            marker.position.lat(),
                            marker.position.lng()
                        );
                        bounds.extend(loc);
                    });

                    map.setCenter(bounds.getCenter());

                    // map.panToBounds(bounds);
                    map.setZoom(5);
                }
            });
        }
    }
}

document.addEventListener("DOMContentLoaded", initMap);
ajaxFunctionsArray.push(function () {
    initMap();
});

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Object-Fit Fix for Edge */

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

function objectFitVideosforEdge() {
    document.querySelectorAll("video").forEach((v) => {
        let vid = v;
        let ratio = vid.videoWidth / vid.videoHeight;
        if (!isNaN(parseFloat(ratio))) {
            let orientation =
                vid.videoWidth > vid.videoHeight ? "horizontal" : "vertical";
            if (orientation === "horizontal") {
                orientation = ratio < 1.67 ? "horizontal" : "panorama";
            }
            if (orientation === "vertical") {
                orientation = ratio > 0.833 ? "vertical" : "slim";
            }
            vid.classList.add("orientation--" + orientation);
        } else {
            setTimeout(objectFitVideosforEdge, 500);
        }
    });
}

document.addEventListener("readystatechange", objectFitVideosforEdge);
document.addEventListener("DOMContentLoaded", objectFitVideosforEdge);
window.addEventListener("load", objectFitVideosforEdge);
ajaxFunctionsArray.push(function () {
    objectFitVideosforEdge();
});

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Ticker */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

let tickers = [];

function initAnimation() {
    let container = document.querySelectorAll(".ticker__container");
    document
        .querySelectorAll(".ticker--clone")
        .forEach((e) => e.parentNode.removeChild(e));

    [...container].forEach((el) => {
        let wrapper = el.querySelector(".ticker__wrapper");
        let containerWidth = el.clientWidth;
        let list = el.querySelector(".ticker__content");
        let listWidth = list.clientWidth;
        let cloneNum = Math.ceil(containerWidth / listWidth);

        let listClone;
        for (let i = 0; i < cloneNum; i++) {
            listClone = list.cloneNode(true); // the true is for deep cloning
            listClone.classList.add("ticker--clone");
            wrapper.appendChild(listClone);
        }

        let duration = (listWidth / containerWidth) * 5;

        let ticker = new TimelineMax({repeat: -1, paused: true});
        ticker
            .fromTo(
                wrapper,
                duration,
                {x: 0},
                {x: -listWidth, ease: Linear.easeNone},
                0
            )
            .set(list, {x: 0});
        tickers.push(ticker);

        let w = Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0
        );

        // console.log(w);

        tickers.forEach((t) => {
            if (w >= 960) {
                t.play(0);
            } else {
                t.kill();
            }
        });
    });
}

document.addEventListener("DOMContentLoaded", initAnimation);
window.addEventListener("resize", initAnimation);
ajaxFunctionsArray.push(function () {
    initAnimation();
});

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Shop */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

import Client from "shopify-buy";

const client = Client.buildClient({
    domain: "cap-008-hotel.myshopify.com",
    storefrontAccessToken: "9a5309905505b8b2219f34ec9f66ed92",
});

// console.log(client);

function initPrices() {
    let products = document.querySelectorAll("[data-product]");

    if (document.body.contains(products[0])) {
        products.forEach((product) => {
            // Fetch a single product by ID
            let productIDNum = product.dataset.product,
                productId = btoa("gid://shopify/Product/" + productIDNum),
                elPrice = product.querySelectorAll(".product__price")[0];

            client.product.fetch(productId).then((product) => {
                // Do something with the product
                let productPrice = product.variants[0].price;
                elPrice.innerHTML = "€" + productPrice.replace(".00", "");
            });
        });
    }
}

document.addEventListener("DOMContentLoaded", initPrices);
ajaxFunctionsArray.push(function () {
    initPrices();
});

function initBuyBtn() {
    if (
        document.body.classList.contains("page--hotel") &&
        document.body.classList.value.includes("single--")
    ) {
        const ui = ShopifyBuy.UI.init(client);
        let elBuy = document.getElementById("buy"),
            productID = elBuy.dataset.buy;
        ui.createComponent("product", {
            id: productID,
            node: elBuy,
            moneyFormat: "€{{amount_with_comma_separator}}",
            options: {
                product: {
                    iframe: false,
                    buttonDestination: "cart",
                    isButton: false,
                    layout: "vertical",
                    manifest: ["product", "option"],
                    width: "280px",
                    order: [
                        "img",
                        "imgWithCarousel",
                        "title",
                        "variantTitle",
                        "price",
                        "options",
                        "quantity",
                        "button",
                        "buttonWithQuantity",
                        "description",
                    ],
                    contents: {
                        img: false,
                        imgWithCarousel: false,
                        title: false,
                        variantTitle: false,
                        price: true,
                        options: true,
                        quantity: false,
                        quantityIncrement: false,
                        quantityDecrement: false,
                        quantityInput: true,
                        button: true,
                        buttonWithQuantity: false,
                        description: false,
                    },
                    text: {
                        button: "Add to Cart",
                        outOfStock: "Out of stock",
                        unavailable: "Unavailable",
                    },
                },

                option: {
                    contents: {
                        option: true,
                    },
                    order: ["option"],
                    classes: {
                        option: "shopify-buy__option-select",
                        wrapper: "shopify-buy__option-select-wrapper",
                        select: "shopify-buy__option-select__select",
                        label: "shopify-buy__option-select__label",
                        optionDisabled: "shopify-buy__option--disabled",
                        optionSelected: "shopify-buy__option--selected",
                        selectIcon: "shopify-buy__select-icon",
                        hiddenLabel: "visuallyhidden",
                    },
                },
                cart: {
                    iframe: false,

                    startOpen: false,
                    popup: true,
                    manifest: ["cart", "lineItem", "toggle"],
                    contents: {
                        title: true,
                        lineItems: true,
                        footer: true,
                        note: false,
                    },
                    order: ["title", "lineItems", "footer"],
                    classes: {
                        wrapper: "shopify-buy__cart-wrapper",
                        cart: "shopify-buy__cart",
                        header: "shopify-buy__cart__header",
                        title: "shopify-buy__cart__title",
                        lineItems: "shopify-buy__cart-items",
                        footer: "shopify-buy__cart-bottom",
                        subtotalText: "shopify-buy__cart__subtotal__text",
                        subtotal: "shopify-buy__cart__subtotal__price",
                        notice: "shopify-buy__cart__notice",
                        currency: "shopify-buy__cart__currency",
                        button: "shopify-buy__btn shopify-buy__btn--cart-checkout",
                        close: "shopify-buy__btn--close",
                        cartScroll: "shopify-buy__cart-scroll",
                        cartScrollWithCartNote: "shopify-buy__cart-scroll--cart-note",
                        empty: "shopify-buy__cart-empty-text",
                        note: "shopify-buy__cart__note",
                        noteDescription: "shopify-buy__cart__note__description",
                        noteTextArea: "shopify-buy__cart__note__text-area",
                    },
                    text: {
                        title: "Cart",
                        empty: "Your cart is empty.",
                        button: "Checkout",
                        total: "Subtotal",
                        currency: "EUR",
                        notice: "Shipping and discount codes are added at checkout.",
                        noteDescription: "Special instructions for seller",
                    },
                },
                lineItem: {
                    contents: {
                        image: true,
                        variantTitle: true,
                        title: true,
                        price: true,
                        quantity: true,
                        quantityIncrement: true,
                        quantityDecrement: true,
                        quantityInput: true,
                    },
                    order: ["image", "title", "variantTitle", "quantity", "price"],
                    classes: {
                        lineItem: "shopify-buy__cart-item",
                        image: "shopify-buy__cart-item__image",
                        variantTitle: "shopify-buy__cart-item__variant-title",
                        itemTitle: "shopify-buy__cart-item__title",
                        price: "shopify-buy__cart-item__price",
                        quantity: "shopify-buy__quantity-container clearfix",
                        quantityInput:
                            "shopify-buy__quantity shopify-buy__cart-item__quantity-input",
                        quantityButton: "shopify-buy__btn--seamless",
                        quantityIncrement: "shopify-buy__quantity-increment",
                        quantityDecrement: "shopify-buy__quantity-decrement",
                    },
                },
                toggle: {
                    manifest: ["toggle"],
                    iframe: false,
                    sticky: true,
                    contents: {
                        count: true,
                        icon: false,
                        title: false,
                    },
                    order: ["count", "icon", "title"],
                    classes: {
                        wrapper: "shopify-buy__cart-toggle-wrapper",
                        toggle: "shopify-buy__cart-toggle",
                        title: "shopify-buy__cart-toggle__title",
                        count: "shopify-buy__cart-toggle__count",
                        icon: "shopify-buy__icon-cart shopify-buy__icon-cart--side",
                        iconPath: "shopify-buy__icon-cart__group",
                    },
                    text: {
                        title: "cart",
                    },
                },
            },
        });
    } else {
    }
}

document.addEventListener("DOMContentLoaded", initBuyBtn);
ajaxFunctionsArray.push(function () {
    initBuyBtn();
});

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Locations Filter */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

import Shuffle from "shufflejs";

document
    .querySelector("#btn__locations__filters")
    ?.addEventListener("click", ({target}) => {
        const parent = target.parentNode,
            active = parent.classList.contains("filters--active");

        if (!active) {
            target.innerHTML = target.dataset.close;
            parent.classList.add("filters--active");
        } else {
            target.innerHTML = target.dataset.open;
            parent.classList.remove("filters--active");
        }
    });

const getMergedObjs = (...objs) =>
    Object.fromEntries(
        Object.entries(
            [{}, ...objs].map((e, i, a) =>
                i
                    ? Object.entries(e).map((f) =>
                        a[0][f[0]]
                            ? a[0][f[0]].push(...[f[1]].flat())
                            : (a[0][f[0]] = [f[1]].flat())
                    )
                    : e
            )[0]
        ).map((e) => e.map((f, i) => (i ? f.map((o) => o.split(", ")) : f)))
    );

function initLocationsFilter() {
    const itemsLocation = document.querySelectorAll(".locations__items");

    if (itemsLocation.length === 0) {
        return null;
    }

    const shuffleInstance = new Shuffle(
        document.querySelectorAll(".locations__items")[0],
        {
            itemSelector: ".locations__item",
            sizer: ".locations__item",
            delimiter: ",",
            filterMode: Shuffle.FilterMode.ALL,
        }
    );

    Shuffle.ShuffleItem.Scale.HIDDEN = 1;

    let filters = {};

    const filterCategories = document.querySelectorAll(
        ".location__filters__categories"
    );

    const filterResetBtn = document.querySelector("#btn__locations--reset"),
        filterCountrySelect = document.querySelector("#location__filter--country"),
        filterBtns = document.querySelectorAll(".location__filter input");

    filterResetBtn.addEventListener("click", () => {
        shuffleInstance.filter(Shuffle.ALL_ITEMS);
        filterCountrySelect.querySelectorAll("select")[0].value = "All";
        [...filterBtns].map((input) => {
            input.checked = false;
            input.disabled = false;
        });
    });

    filterCountrySelect.addEventListener("change", (e) => {
        const vSelected = e.target.options[e.target.selectedIndex].value;

        centerMapOnCountry && centerMapOnCountry(vSelected);

        [...filterBtns].map((input) => (input.checked = false));

        if (vSelected === "All") {
            [...filterBtns].map((input) => (input.disabled = false));
            filters.country = [];
            shuffleInstance.filter(Shuffle.ALL_ITEMS);
        } else {
            shuffleInstance.filter(function ({dataset}) {
                return dataset.country.includes(vSelected);
            });

            let datasets = [];

            document
                .querySelectorAll(".shuffle-item--visible")
                .forEach((el) => datasets.push({...el.dataset}));

            const objMerged = getMergedObjs(...datasets),
                objFlat = {},
                objUnique = {};

            objMerged.forEach(
                (value, key) =>
                    (objFlat[key] = value.reduce((a, b) => [...a, ...b], []))
            );

            objFlat.forEach((value, key) => (objUnique[key] = [...new Set(value)]));

            [...filterBtns].map((input) => {
                input.disabled =
                    objUnique[input.dataset.key] &&
                    !objUnique[input.dataset.key].includes(input.dataset.value);
            });

            filters.country = vSelected;
        }
    });

    filterCategories.forEach((filterCategory) => {
        const category = filterCategory.getAttribute("id").split("--")[1];

        filters[category] = [];

        filterBtns.forEach((filterBtn) =>
            filterBtn.addEventListener("change", (e) => {
                const {target} = e;

                target.checked = !target.checked;
                filters[target.dataset.key] = [...filterBtns]
                    .filter(
                        (checkbox) =>
                            checkbox.checked && checkbox.dataset.key === target.dataset.key
                    )
                    .map((input) => input.dataset.value);

                // If is empty
                if (Object.values(filters).every((x) => !x.length)) {
                    filters.country.length === 0 &&
                    shuffleInstance.filter(Shuffle.ALL_ITEMS);
                } else {
                    shuffleInstance.filter(function ({dataset}) {
                        let result;
                        const options = {...dataset};

                        options.forEach((value, key) => {
                            result = !result
                                ? value.split(", ").some((v) => filters[key].includes(v))
                                : result;
                        });

                        const countryMatch =
                            filters.country.length === 0 ||
                            filters.country === options.country;

                        return result && countryMatch;
                    });
                }
            })
        );
    });
}

document.addEventListener("DOMContentLoaded", initAgencyFilter);
ajaxFunctionsArray.push(function () {
    initAgencyFilter();
});

function initAgencyFilter() {
    const agencyCategoriesFilters = document.querySelectorAll(".agency-categories--filter");

    if (agencyCategoriesFilters.length === 0) {
        return null;
    }

    agencyCategoriesFilters?.forEach(filter => {
        const agencyCategoriesElements = filter.closest('div').querySelectorAll(".agency-categories--element");
        filter.addEventListener('click', event => {
            event.preventDefault();
            agencyCategoriesElements?.forEach(element => {
                if (event.target.dataset.category === 'all') {
                    element.classList.remove('hidden')
                    for (let sibling of event.target.parentNode.parentNode.children) {
                        sibling.children[0].classList.remove('active');
                    }
                } else {
                    element.classList.toggle('hidden', element.dataset.category.split(', ').filter(c => c === event.target.dataset.category).length === 0)
                    event.target.classList.add('active')

                    for (let sibling of event.target.parentNode.parentNode.children) {
                        if (sibling.children[0] !== event.target) {
                            sibling.children[0].classList.remove('active');
                        }
                    }
                }
            })
        });
    })

    const talentNamesList = document.querySelector('.talent-names--list');
    const fadingBottom = document.querySelector('.fading--bottom');
    const fadingTop = document.querySelector('.fading--top');
    talentNamesList?.addEventListener('scroll', event => {
        const target = event.target;
        fadingBottom.classList.toggle('hidden', target.scrollHeight - target.scrollTop === target.clientHeight)
        fadingTop.classList.toggle('hidden', target.scrollTop < 10)
    })
}


document.addEventListener("DOMContentLoaded", initMouseoverThumbnail);
ajaxFunctionsArray.push(function () {
    initMouseoverThumbnail();
});

function initMouseoverThumbnail() {
    if (!supportsTouch) {
        let tooltip = document.querySelector('.mouseover-thumbnail')
        let img

        if (!tooltip) {
            tooltip = document.createElement('div');
            img = document.createElement('img')
            tooltip.appendChild(img)
            document.body.appendChild(tooltip)
            tooltip.className = 'mouseover-thumbnail'
        } else {
            img = tooltip.querySelector('img')
        }

        const hovers = document.querySelectorAll('.has-hover')

        hovers?.forEach(element => {
            const link = element.querySelector('a')
            if (link.hasAttribute('data-hover')) {
                link.addEventListener('mousemove', event => {
                    tooltip.classList.add('visible')
                    img.src = link.dataset.hover
                    tooltip.style.inset = `${window.scrollY + event.clientY + 5}px auto auto ${event.clientX + 5}px`
                })
                link.addEventListener('mouseout', event => {
                    tooltip.classList.remove('visible')
                })
            }
        })
    }
}

document.body.addEventListener('submit', function (event) {
    if (event.target.matches('.newsletter--form')) {
        event.preventDefault()
        const newsletter_response = document.querySelector('.newsletter--response')
        newsletter_response.textContent = 'Processing...'

        const email = document.getElementById('newsletter-email').value;

        if (!email) {
            newsletter_response.textContent = 'Please enter a valid email address!';
            return;
        }

        if (!document.getElementById('newsletter-terms').checked) {
            newsletter_response.textContent = 'Please accept our terms and conditions.';
            return;
        }

        fetch(newsletterAjax.ajaxurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                action: 'newsletter_subscribe',
                email: email,
                security: newsletterAjax.nonce
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    newsletter_response.textContent = 'Success: ' + data.data;
                    document.getElementById('newsletter-email').value = '';
                } else {
                    newsletter_response.textContent = 'Error: ' + data.data;
                }
            })
            .catch(error => {
                console.error('Error:', error);
                newsletter_response.textContent = 'An error occurred.';
            });
    }
});
